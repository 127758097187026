<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        xl="12"
        md="12"
      >
        <b-overlay
          :show="overlayShow"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <store-statistics
            v-if="statsData"
            :data="statsData"
          />
        </b-overlay>
      </b-col>
    </b-row>

  </section>
</template>

<script>
import axios from '@axios'
import StoreStatistics from './StoreStatistics.vue'

export default {
  components: {
    StoreStatistics,
  },
  data() {
    return {
      statsData: {},
      overlayShow: true,
    }
  },

  created() {
    this.getStats()
  },

  methods: {
    getStats() {
      axios.get('reports/home')
        .then(response => {
          this.overlayShow = false
          this.statsData = response.data
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
